(function() {
    'use strict';

    angular.module('uabDefaultImage', [
        'uabDefaultVariable',
        'uabEnvironment'
    ]);
})();
(function() {
    'use strict';

    angular.module('uabDefaultImage').controller(
        'DefaultImageController',
        DefaultImageController
    );

    DefaultImageController.$inject = [
        'DefaultImageService'
    ];

    function DefaultImageController(
        DefaultImageService
    ) {
        var DefaultImageController = this;

        DefaultImageController.getArticleImage = getArticleImage;
        function getArticleImage(article) {
            return DefaultImageService.getArticleImage(article);
        }

        DefaultImageController.getBadgeImage = getBadgeImage;
        function getBadgeImage(badge) {
            return DefaultImageService.getBadgeImage(badge);
        }

        DefaultImageController.getNotificationImage = getNotificationImage;
        function getNotificationImage(playlist) {
            return DefaultImageService.getNotificationImage(playlist);
        }

        DefaultImageController.getPlaylistImage = getPlaylistImage;
        function getPlaylistImage(playlist) {
            return DefaultImageService.getPlaylistImage(playlist);
        }

        DefaultImageController.getUserImage = getUserImage;
        function getUserImage(user) {
            return DefaultImageService.getUserImage(user);
        }

        DefaultImageController.getVideoImage = getVideoImage;
        function getVideoImage(video) {
            return DefaultImageService.getVideoImage(video);
        }

        DefaultImageController.imageIsSet = imageIsSet;
        function imageIsSet(object) {
            return DefaultImageService.imageIsSet(object);
        }
    }
})();
(function() {
    'use strict';

    angular.module('uabDefaultImage').directive('defaultImage', defaultImage);

    function defaultImage() {
        return {
            controller:   'DefaultImageController',
            controllerAs: 'defaultImageCtrl',
            restrict:     'E',
            template:'<div><div>{{ defaultImageCtrl.getArticleImage() }}</div><div>{{ defaultImageCtrl.getBadgeImage() }}</div><div>{{ defaultImageCtrl.getNotificationImage() }}</div><div>{{ defaultImageCtrl.getPlaylistImage() }}</div><div>{{ defaultImageCtrl.getUserImage() }}</div><div>{{ defaultImageCtrl.getVideoImage() }}</div></div>'
        };
    }
})();
(function() {
    'use strict';

    angular.module('uabDefaultImage').service(
        'DefaultImageService',
        DefaultImageService
    );

    DefaultImageService.$inject = [
        'DefaultVariableService',
        'EnvironmentService'
    ];

    function DefaultImageService(
        DefaultVariableService,
        EnvironmentService
    ) {
        var DefaultImageService = this;

        var DEFAULT_ARTICLE_PATH = 'defaultArticleImage';
        var DEFAULT_BADGE_PATH = 'defaultBadgeImage';
        var DEFAULT_FEMALE_USER_PATH = 'defaultFemaleImage';
        var DEFAULT_MALE_USER_PATH = 'defaultMaleImage';
        var DEFAULT_NOTIFICATION_PATH = 'defaultNotificationImage';
        var DEFAULT_PLAYLIST_PATH = 'defaultPlaylistImage';
        var DEFAULT_VIDEO_PATH = 'defaultVideoImage';

        DefaultImageService.getArticleImage = getArticleImage;
        function getArticleImage(article) {
            return DefaultImageService.getImage(article, DEFAULT_ARTICLE_PATH);
        }

        DefaultImageService.getBadgeImage = getBadgeImage;
        function getBadgeImage(badge) {
            return DefaultImageService.getImage(badge, DEFAULT_BADGE_PATH);
        }

        DefaultImageService.getFilePath = getFilePath;
        function getFilePath(object) {
            if (DefaultVariableService.isDefined(object)) {
                if (DefaultImageService.imageIsSet(object)) {
                    return object.image.file_path;
                } else {
                    return DefaultVariableService.get(
                        object.file_path,
                        false
                    );
                }
            }

            return false;
        }

        DefaultImageService.getImage = getImage;
        function getImage(object, backupString) {
            var filePath = DefaultImageService.getFilePath(object);

            if (filePath) {
                var prefix = '';
                if (filePath.indexOf('base64') === -1) {
                    prefix = EnvironmentService.get('backendUrl');
                }

                var prefixEndsWithSlash = prefix.slice(-1) === '/';
                var filePathBeginsWithSlash = filePath.slice(0, 1) === '/';
                if (prefixEndsWithSlash && filePathBeginsWithSlash) {
                    prefix = prefix.slice(0, -1);
                } else if (!prefixEndsWithSlash && !filePathBeginsWithSlash) {
                    prefix += '/';
                }

                return prefix + filePath;
            } else {
                return EnvironmentService.get(backupString);
            }
        }

        DefaultImageService.getNotificationImage = getNotificationImage;
        function getNotificationImage(notification) {
            return DefaultImageService.getImage(notification, DEFAULT_NOTIFICATION_PATH);
        }

        DefaultImageService.getPlaylistImage = getPlaylistImage;
        function getPlaylistImage(playlist) {
            return DefaultImageService.getImage(playlist, DEFAULT_PLAYLIST_PATH);
        }

        DefaultImageService.getUserImage = getUserImage;
        function getUserImage(user) {
            var backupString = '';
            if (DefaultVariableService.isDefined(user) && user.gender) {
                backupString = DEFAULT_FEMALE_USER_PATH;
            } else {
                backupString = DEFAULT_MALE_USER_PATH;
            }

            return DefaultImageService.getImage(user, backupString);
        }

        DefaultImageService.getVideoImage = getVideoImage;
        function getVideoImage(video) {
            return DefaultImageService.getImage(video, DEFAULT_VIDEO_PATH);
        }

        DefaultImageService.imageIsSet = imageIsSet;

        function imageIsSet(object) {
            return DefaultVariableService.isObject(object) && DefaultVariableService.isObject(object.image);
        }

        return DefaultImageService;
    }
})();